<template>
  <div
    class="alert bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10"
  >
    <!--begin::Wrapper-->
    <div class="text-center">
      <!--begin::Title-->
      <h1 class="fw-bolder mb-5">Error</h1>
      <!--end::Title-->

      <!--begin::Separator-->
      <div
        class="separator separator-dashed border-danger opacity-25 mb-5"
      ></div>
      <!--end::Separator-->

      <!--begin::Content-->
      <div class="mb-9 text-dark">
        {{ resolvedMessage }}
      </div>
      <!--end::Content-->

      <!--begin::Buttons-->
      <div v-if="hasRefreshListener" class="d-flex flex-center flex-wrap">
        <button
          class="btn btn-outline btn-outline-danger btn-active-danger m-2"
          @click="$emit('refresh')"
        >
          Refresh
        </button>
      </div>
      <!--end::Buttons-->
    </div>
    <!--end::Wrapper-->
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import axios, { AxiosError } from "axios";

export default defineComponent({
  name: "RequestFailedError",
  props: {
    message: {
      type: String,
      required: false,
      default: null,
    },
    exception: {
      type: Object as PropType<Error | AxiosError>,
      required: false,
      default: null,
    },
  },
  emits: ["refresh"],
  computed: {
    hasRefreshListener() {
      return this.$attrs && this.$attrs.onRefresh;
    },
    resolvedMessage(): string {
      if (axios.isAxiosError(this.exception)) {
        return this.exception.response?.data?.message || this.exception.message;
      }
      return "Something went wrong";
    },
  },
});
</script>

<style scoped></style>
