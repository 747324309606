
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import LayoutPicker from "@/modules/common/widgets/LayoutPicker/LayoutPicker.vue";

export default defineComponent({
  name: "HeaderOrderInfo",
  components: { LayoutPicker },
  setup() {
    const store = useStore();
    const route = useRoute();

    const orderInfo = computed(() => {
      let info = computed(() => store.state.OrderInfoModule);
      if (
        info.value.order &&
        route.name === "orders-view" &&
        route.params.id != null
      ) {
        return info;
      }
      return null;
    });

    const stormUrl = process.env.VUE_APP_EXTERNAL_STORM_URL;

    const layout = localStorage.layout;
    return {
      route,
      orderInfo,
      stormUrl,
      layout,
    };
  },
});
