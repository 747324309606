
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessageOut",
  props: {
    name: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
});
