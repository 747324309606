
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { defineComponent } from "vue";
import RequestFailedError from "@/components/errors/RequestFailedError.vue";
import LoadingIndicator from "@/components/spinners/LoadingIndicator.vue";
import Layout from "@/layout/Layout.vue";

interface ComponentData {
  loaded: boolean;
  error: boolean;
}

export default defineComponent({
  name: "LayoutWrapper",
  components: { RequestFailedError, LoadingIndicator, Layout },
  data(): ComponentData {
    return {
      loaded: false,
      error: false,
    };
  },
  computed: {
    store: () => useStore(),
    router: () => useRouter(),
  },
  mounted() {
    this.prepareLayout();
  },
  methods: {
    prepareLayout: function () {
      if (!this.store.getters.isUserAuthenticated) {
        this.router.push({ name: "sign-in" });
        return;
      }

      this.error = false;
      this.loaded = false;
      ApiService.setAuthorizationHeader();
      ApiService.get("auth/me")
        .then(({ data }) => {
          this.store.commit(Mutations.SET_AUTH, data);
          this.loaded = true;
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
});
