
import { defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import HeaderOrderInfo from "@/modules/common/widgets/HeaderOrderInfo/HeaderOrderInfo.vue";

export default defineComponent({
  name: "KTHeader",
  components: {
    HeaderOrderInfo,
    KTTopbar,
    KTMenu,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
