<template>
  <div>
    <table class="text-center mt-5 header_info_text">
      <tr>
        <td>
          <layout-picker />
        </td>
        <td v-if="orderInfo && layout !== 'packing'" class="px-3">
          <a
            :href="stormUrl + 'order/' + orderInfo.value.order.reference"
            target="_blank"
            class="btn btn-secondary btn-sm"
          >
            To Storm
          </a>
        </td>
        <td v-if="orderInfo" class="px-3">
          {{ orderInfo.value.order.reference }}
        </td>
        <td v-if="orderInfo" class="px-3">
          <span
            v-if="orderInfo.value.order.status"
            class="badge header_info_text p-2"
            :style="'background-color:' + orderInfo.value.order.status.color"
            >{{
              orderInfo.value.order.status.title ||
              orderInfo.value.order.status.name
            }}
          </span>
        </td>
        <td v-if="orderInfo" class="px-3">
          {{ orderInfo.value.order.created_at }}
        </td>
        <td v-if="orderInfo" class="px-3">
          <span
            v-if="orderInfo.value.order.status"
            class="badge header_info_text item_counter p-2"
          >
            {{ orderInfo.value.order.total_items }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import LayoutPicker from "@/modules/common/widgets/LayoutPicker/LayoutPicker.vue";

export default defineComponent({
  name: "HeaderOrderInfo",
  components: { LayoutPicker },
  setup() {
    const store = useStore();
    const route = useRoute();

    const orderInfo = computed(() => {
      let info = computed(() => store.state.OrderInfoModule);
      if (
        info.value.order &&
        route.name === "orders-view" &&
        route.params.id != null
      ) {
        return info;
      }
      return null;
    });

    const stormUrl = process.env.VUE_APP_EXTERNAL_STORM_URL;

    const layout = localStorage.layout;
    return {
      route,
      orderInfo,
      stormUrl,
      layout,
    };
  },
});
</script>

<style scoped>
.header_info_text {
  font-size: 1.4rem;
}
.item_counter {
  background-color: #5cb85c;
}
</style>
