<template>
  <div>
    <select
      v-model="state.pickedLayout"
      class="form-select"
      @change="changeLayout()"
    >
      <option value="basic" selected>Basic</option>
      <option value="packing">Packing</option>
      <option value="tracking">Tracking</option>
    </select>
  </div>
</template>

<script lang="ts">
interface ComponentData {
  loading: boolean;
  pickedLayout: string;
}

import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "LayoutPicker",
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      loading: true,
      pickedLayout: "basic",
    });

    const router = useRouter();

    if (localStorage.layout) {
      state.pickedLayout = localStorage.layout;
    } else {
      localStorage.layout = "basic";
      state.pickedLayout = localStorage.layout;
    }

    const changeLayout = (): void => {
      localStorage.layout = state.pickedLayout;

      router.go(0);
    };

    return {
      props,
      emit,
      state,
      changeLayout,
    };
  },
});
</script>

<style scoped>
.form-select {
  padding: 5px 35px 5px 10px;
}
</style>
