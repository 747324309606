import { useHead } from "@vueuse/head";

const removeModalBackdrop = () => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

export { removeModalBackdrop };

export const setPageTitle = (title: string): void => {
  useHead({
    title: title + " | Wind",
  });
};
