
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "KtUserMenu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const printTest = () => {
      ApiService.post(`print/test-print`)
        .then((data) => {
          Swal.fire({
            text: "Print test request was successfully sent!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    return {
      signOut,
      printTest,
      user: store.getters.currentUser,
    };
  },
});
