
interface ComponentData {
  loading: boolean;
  pickedLayout: string;
}

import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "LayoutPicker",
  setup(props, { emit }) {
    const state = reactive<ComponentData>({
      loading: true,
      pickedLayout: "basic",
    });

    const router = useRouter();

    if (localStorage.layout) {
      state.pickedLayout = localStorage.layout;
    } else {
      localStorage.layout = "basic";
      state.pickedLayout = localStorage.layout;
    }

    const changeLayout = (): void => {
      localStorage.layout = state.pickedLayout;

      router.go(0);
    };

    return {
      props,
      emit,
      state,
      changeLayout,
    };
  },
});
