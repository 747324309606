<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-375px"
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <img alt="Logo" src="media/avatars/150-26.jpg" />
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ user.name }}
          </div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-7" style="line-break: anywhere;">
            {{ user.email }}
          </a>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a class="menu-link px-5" @click="printTest()"> Print test </a>
    </div>
    <div class="menu-item px-5">
      <a class="menu-link px-5" @click="signOut()"> Sign Out </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "KtUserMenu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const printTest = () => {
      ApiService.post(`print/test-print`)
        .then((data) => {
          Swal.fire({
            text: "Print test request was successfully sent!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        })
        .catch((error) => {
          Notify.apiError(error);
        });
    };

    return {
      signOut,
      printTest,
      user: store.getters.currentUser,
    };
  },
});
</script>
