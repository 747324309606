<template>
  <span
    class="spinner-border align-middle ms-2"
    :class="{
      'spinner-border-sm': size === 'sm',
    }"
  ></span>
</template>

<script>
export default {
  name: "LoadingIndicator",
  props: {
    size: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
