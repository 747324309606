
import { defineComponent, PropType } from "vue";
import axios, { AxiosError } from "axios";

export default defineComponent({
  name: "RequestFailedError",
  props: {
    message: {
      type: String,
      required: false,
      default: null,
    },
    exception: {
      type: Object as PropType<Error | AxiosError>,
      required: false,
      default: null,
    },
  },
  emits: ["refresh"],
  computed: {
    hasRefreshListener() {
      return this.$attrs && this.$attrs.onRefresh;
    },
    resolvedMessage(): string {
      if (axios.isAxiosError(this.exception)) {
        return this.exception.response?.data?.message || this.exception.message;
      }
      return "Something went wrong";
    },
  },
});
